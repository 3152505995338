var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", [_vm._v("关联拖拽测试")]),
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "left" },
        [_c("unit", { attrs: { list: _vm.list } })],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c(
            "draggable",
            {
              staticClass: "right_d",
              attrs: {
                list: _vm.transform,
                group: { name: "transform", pull: "clone", put: false },
              },
            },
            _vm._l(_vm.transform, function (item, index) {
              return _c("div", { key: index, staticClass: "btn_unit" }, [
                _vm._v("\n          " + _vm._s(item.name) + "\n        "),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }