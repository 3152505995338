<!--
 * @Author       : Hugo
 * @Date         : 2020-10-29 10:21:14
 * @LastEditTime : 2020-12-02 15:37:25
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/test/DraggableUnit.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <draggable
    class="left_drag"
    :list="list"
    @change="changeLog"
    :group="{ name: 'transform'}"
    animation="500"
    :sort="true"
    >
    <div
      class="control_content_group"
      v-for="(item, index) in list"
      :key="index">
      <div
        class="control_unit_group"
        ref="control_unit">
        {{item.name}}
        <unit :list="item.list"></unit>
      </div>
      
    </div>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  name: 'unit',
  components: {
    draggable,
  },
  props:{
    list: {
      required: true,
      type: Array,
    }
  },
  methods:{
    changeLog(evt){
      // console.log(evt);
    }
  }
}
</script>
<style lang="scss" scoped>
.left_drag{
  min-height: 50px;
  width: 100%;
  .control_content_group{
    @include bbox;
    padding: 20px;
    width: 100%;
    min-height: 60px;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}
</style>