<!--
 * @Author       : Hugo
 * @Date         : 2020-10-29 10:14:51
 * @LastEditTime : 2020-10-29 11:02:29
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/views/test/DraggableNest.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div>
    <h2>关联拖拽测试</h2>
    <div class="main">
      <div class="left">
        <unit :list="list"></unit>
      </div>
      <div class="right">
        <draggable
          class="right_d"
          :list="transform"
          :group="{name: 'transform', pull: 'clone', put: false }"
        >
          <div
            class="btn_unit"
            v-for="(item, index) in transform"
            :key="index"
          >
            {{item.name}}
          </div>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import Unit from '@/views/test/DraggableUnit.vue';
export default {
  components: {
    Unit,
    draggable,
  },
  data(){
    return {
      list:[],
      transform:[
        {type:1,name: '礼拜一', list:[],},
        {type:2,name: '礼拜二', list:[],},
        {type:3,name: '礼拜3', list:[],},
        {type:4,name: '礼拜4', list:[],},
        {type:5,name: '礼拜5', list:[],},
        {type:6,name: '礼拜6', list:[],},
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.main{
  width: 100%;
  padding: 20px;
  @include flex;
  height: 1000px;
  @include bbox;
  background-color: #ccc;
  align-items: stretch;
  .left{
    @include bbox;
    padding: 20px;
    width: 100%;
    flex: 1;
  }
  .right{
    @include bbox;
    padding: 20px;
    width: 400px;
    border-left: 2px solid #666;
    .right_d{
      width: 100%;
      .btn_unit{
        @include bbox;
        padding: 0 10px;
        width: 100%;
        font-size: 16px;
        line-height: 40px;
        border-radius: 4px;
        border: 1px solid #fff;
        margin-bottom: 10px;
      }
    }
  }
}
</style>