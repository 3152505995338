var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "left_drag",
      attrs: {
        list: _vm.list,
        group: { name: "transform" },
        animation: "500",
        sort: true,
      },
      on: { change: _vm.changeLog },
    },
    _vm._l(_vm.list, function (item, index) {
      return _c("div", { key: index, staticClass: "control_content_group" }, [
        _c(
          "div",
          {
            ref: "control_unit",
            refInFor: true,
            staticClass: "control_unit_group",
          },
          [
            _vm._v("\n      " + _vm._s(item.name) + "\n      "),
            _c("unit", { attrs: { list: item.list } }),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }